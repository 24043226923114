import React, { useEffect, useState } from "react";
import { Alert, Grid, LinearProgress, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getRegionalOffices } from "../api/getRegionalOffices";
import { getDistrictOffices } from "../api/getDistrictOffices";
import { enqueueSnackbar } from "notistack";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { addIssue } from "../api/addIssue";
import CheckIcon from "@mui/icons-material/Check";

export default function Form({ requestType, affectsYou }) {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  const [regionalOffices, setRegionalOffices] = useState(null);
  const [districtOffices, setDistrictOffices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [issueKey, setIssueKey] = useState(null);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const recaptchaRef = React.createRef();

  useEffect(() => {
    getRegionalOffices()
      .then((res) => {
        setRegionalOffices(res.data);
      })
      .catch((error) => {
        enqueueSnackbar("[Chorągwie] Błąd pobierania danych: " + error, {
          variant: "error",
        });
      });
  }, []);

  const schema = Yup.object().shape({
    captcha: Yup.string().required("Weryfikacja reCAPTCHA jest wymagana!"),
    description: Yup.string()
      .min(
        10,
        "Opis sytuacji przekroczenia granic musi mieć conajmniej 10 znaków!"
      )
      .required("Opis sytuacji przekroczenia granic jest wymagany!"),
    emailAddress: Yup.string().email("Adres e-mail jest niepoprawny!"),
    emailAddressCampCommisioner: Yup.string().email(
      "Adres e-mail jest niepoprawny!"
    ),
  });

  return (
    <Box id="form">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap>
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            sx={{
              width: { sm: "100%", md: "100%" },
            }}
          >
            Formularz zgłoszenia przekroczenia granic
          </Typography>
          <Formik
            validationSchema={schema}
            initialValues={{
              anonymous: requestType === 0 ? "true" : "false",
              affectsYou: affectsYou === 0 ? "true" : "false",
              nameSurname:
                affectsYou !== 0 && requestType === 2
                  ? instance.getActiveAccount().name
                  : "",
              phoneNumber: "",
              emailAddress:
                requestType === 2 ? instance.getActiveAccount().username : "",
              role: "",
              age: "",
              nameSurnameVictim:
                affectsYou === 0 && requestType === 2
                  ? instance.getActiveAccount().name
                  : "",
              regionalOffice: "",
              districtOffice: "",
              ageVictim: "",
              nameSurnameGuilty: "",
              ageGuilty: "",
              infoGuilty: "",
              description: "",
              isCrime: "",
              policeInformed: "",
              actions: "",
              isCamp: "",
              nameSurnameCampCommisioner: "",
              emailAddressCampCommisioner: "",
              phoneNumberCampCommisioner: "",
              isDistrictCommisionerGuilty: "",
              isRegionalOfficeCommisionerGuilty: "",
              captcha: null,
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log(JSON.stringify(values, null, 2));
              setLoading(true);

              addIssue(JSON.stringify(values, null, 2))
                .then((res) => {
                  setLoading(false);
                  setIssueKey(res.data.key);
                })
                .catch((error) => {
                  setLoading(false);
                  enqueueSnackbar(
                    "[Formularz] Błąd wysyłania formularza: " + error,
                    {
                      variant: "error",
                    }
                  );
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              handleReset,
              setFieldValue,
              /* and other goodies */
            }) => (
              <>
                {loading && (
                  <Grid style={{ marginTop: 20 }}>
                    Trwa ładowanie danych...
                    <LinearProgress color="success" />
                  </Grid>
                )}
                {issueKey && (
                  <Grid style={{ marginTop: 20 }}>
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                      sx={{ mb: 3, fontSize: "large" }}
                    >
                      <strong>Dziękujemy za przesłanie tego zgłoszenia!</strong>
                      <br /> Jeśli podałeś/aś swój numer telefonu, Strefa Pomocy
                      ZHP skontaktuje się z Tobą w jego sprawie. Jeśli w
                      zgłoszeniu nie zostały podane dane kontaktowe, sprawa
                      zostanie przekazana do odpowiedniego komendanta hufca,
                      komendanta chorągwi lub na poziom centralny.
                      <br />
                      <br />
                      Zgłoszenie otrzymało numer <strong>{issueKey}</strong>.
                      <br />
                      <br />
                      Dziękujemy za Twoje zaangażowanie w budowanie kultury
                      bezpieczeństwa w ZHP!
                    </Alert>

                    <ColorButton
                      onClick={() => {
                        handleReset();
                        setIssueKey(null);
                      }}
                      variant="contained"
                    >
                      POWRÓT
                    </ColorButton>
                  </Grid>
                )}
                {!loading && !issueKey && (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      {(values.affectsYou === "false" ||
                        values.affectsYou === "") &&
                        values.anonymous === "false" && (
                          <>
                            <Grid xs={12}>
                              <h2>Osoba zgłaszająca</h2>
                              <Typography>
                                Jeśli podasz swoje dane kontaktowe w tym
                                formularzu, zostaną one wykorzystane do kontaktu
                                z Tobą w celu weryfikacji tego, jak czujesz się
                                po dokonaniu zgłoszenia i czy nie potrzebujesz
                                dodatkowej pomocy oraz zostaną przekazane osobie
                                prowadzącej interwencję w tej sprawie (jeśli nie
                                jesteś nią Ty). Pytanie o funkcję w ZHP pozwoli
                                nam wyłapać te osoby, które będą zaangażowane w
                                dalszy proces prowadzenia interwencji w tej
                                sprawie (np. komendant hufca, kierownik
                                wypoczynku). W takiej sytuacji nasza rozmowa
                                będzie miała też na celu opracowanie z Tobą
                                planu działań, które należy podjąć.
                              </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 15 }}>
                              <TextField
                                fullWidth
                                name="nameSurname"
                                id="nameSurname"
                                variant="filled"
                                error={
                                  touched.nameSurname &&
                                  Boolean(errors.nameSurname)
                                }
                                helperText={
                                  touched.nameSurname && errors.nameSurname
                                    ? errors.nameSurname
                                    : "Pamiętaj, że podanie nam swoich danych jest dobrowolne!"
                                }
                                label="Twoje imię i nazwisko"
                                value={values.nameSurname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={
                                  values.affectsYou === "false" &&
                                  requestType === 2
                                }
                              />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 15 }}>
                              <TextField
                                fullWidth
                                name="phoneNumber"
                                id="phoneNumber"
                                variant="filled"
                                error={
                                  touched.phoneNumber &&
                                  Boolean(errors.phoneNumber)
                                }
                                helperText={
                                  touched.phoneNumber && errors.phoneNumber
                                    ? errors.phoneNumber
                                    : "Twój telefon zostanie wykorzystany do nawiązania kontaktu z Tobą w celu weryfikacji tego, jak czujesz się po dokonaniu zgłoszenia i czy nie potrzebujesz dodatkowej pomocy oraz zostaną przekazane osobie prowadzącej interwencję w tej sprawie (jeśli nie jesteś nią Ty)."
                                }
                                label="Twój numer telefonu"
                                value={values.phoneNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 15 }}>
                              <TextField
                                fullWidth
                                name="emailAddress"
                                id="emailAddress"
                                variant="filled"
                                error={
                                  touched.emailAddress &&
                                  Boolean(errors.emailAddress)
                                }
                                helperText={
                                  touched.emailAddress && errors.emailAddress
                                    ? errors.emailAddress
                                    : "Twój adres e-mail zostanie wykorzystany do kontaktu z Tobą."
                                }
                                label={"Twój adres e-mail"}
                                value={values.emailAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="email"
                                disabled={requestType === 2}
                              />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 15 }}>
                              <TextField
                                fullWidth
                                name="role"
                                id="role"
                                variant="filled"
                                error={touched.role && Boolean(errors.role)}
                                helperText={
                                  touched.role && errors.role
                                    ? errors.role
                                    : "Jaką funkcję pełnisz w ZHP? Jeśli nie pełnisz żadnej, przejdź dalej."
                                }
                                label="Twoja funkcja"
                                value={values.role}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 15 }}>
                              <TextField
                                fullWidth
                                name="age"
                                id="age"
                                variant="filled"
                                error={touched.age && Boolean(errors.age)}
                                helperText={
                                  touched.age && errors.age
                                    ? errors.age
                                    : "Ile masz lat?"
                                }
                                label="Twój wiek"
                                value={values.age}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                            </Grid>
                          </>
                        )}
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Kto był uczestnikiem sytuacji, do której doszło?
                          Identyfikacja uczestników sytuacji pomoże podjąć
                          odpowiednie działania w tej sprawie. Jeśli jednak nie
                          chcesz podawać jakichś danych, nie musisz tego robić.
                        </Typography>
                        <h2>
                          {values.affectsYou === "true"
                            ? "Ty (osoba poszkodowana)"
                            : "Osoba poszkodowana"}
                        </h2>
                      </Grid>
                      {((values.affectsYou === "true" &&
                        values.anonymous === "false") ||
                        (values.affectsYou === "false" &&
                          values.anonymous === "true") ||
                        (values.affectsYou === "false" &&
                          values.anonymous === "false")) && (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              name="nameSurnameVictim"
                              id="nameSurnameVictim"
                              variant="filled"
                              error={
                                touched.nameSurnameVictim &&
                                Boolean(errors.nameSurnameVictim)
                              }
                              helperText={
                                touched.nameSurnameVictim &&
                                errors.nameSurnameVictim
                                  ? errors.nameSurnameVictim
                                  : values.affectsYou === "true"
                                  ? ""
                                  : "Osoba, która doświadczyła przekroczenia granic."
                              }
                              label={
                                values.affectsYou === "true"
                                  ? "Twoje imię i nazwisko"
                                  : "Imię i nazwisko osoby poszkodowanej"
                              }
                              value={values.nameSurnameVictim}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={
                                values.affectsYou === "true" &&
                                requestType === 2
                              }
                            />
                          </Grid>
                          {values.affectsYou === "true" && (
                            <>
                              <Grid item xs={12} style={{ marginTop: 15 }}>
                                <TextField
                                  fullWidth
                                  name="phoneNumber"
                                  id="phoneNumber"
                                  variant="filled"
                                  error={
                                    touched.phoneNumber &&
                                    Boolean(errors.phoneNumber)
                                  }
                                  helperText={
                                    touched.phoneNumber && errors.phoneNumber
                                      ? errors.phoneNumber
                                      : "Telefon zostanie wykorzystany do kontaktu z Tobą w celu weryfikacji tego, jak czujesz się po dokonaniu zgłoszenia oraz przedstawienia Ci możliwych dalszych działań w związku z dokonanym zgłoszeniem."
                                  }
                                  label={"Twój numer telefonu"}
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: 15 }}>
                                <TextField
                                  fullWidth
                                  name="emailAddress"
                                  id="emailAddress"
                                  variant="filled"
                                  error={
                                    touched.emailAddress &&
                                    Boolean(errors.emailAddress)
                                  }
                                  helperText={
                                    touched.emailAddress && errors.emailAddress
                                      ? errors.emailAddress
                                      : "Twój adres e-mail zostanie wykorzystany do kontaktu z Tobą."
                                  }
                                  label={"Twój adres e-mail"}
                                  value={values.emailAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="email"
                                  disabled={requestType === 2}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      )}

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          select
                          name="regionalOffice"
                          id="regionalOffice"
                          variant="filled"
                          error={
                            touched.regionalOffice &&
                            Boolean(errors.regionalOffice)
                          }
                          helperText={
                            touched.regionalOffice && errors.regionalOffice
                              ? errors.regionalOffice
                              : "Po wybraniu Chorągwi z listy załadują się Hufce."
                          }
                          value={values.regionalOffice}
                          onChange={(e) => {
                            setFieldValue("districtOffice", "");
                            setDistrictOffices(null);
                            handleChange(e);
                            getDistrictOffices(e.target.value)
                              .then((res) => {
                                setDistrictOffices(res.data);
                              })
                              .catch((error) => {
                                enqueueSnackbar(
                                  "[Hufce] Błąd pobierania danych: " + error,
                                  {
                                    variant: "error",
                                  }
                                );
                              });
                          }}
                          onBlur={handleBlur}
                          label={
                            values.affectsYou === "true"
                              ? "Z jakiej chorągwi jesteś?"
                              : "Z jakiej chorągwi jest osoba, która doświadczyła przekroczenia granic?"
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>Wybierz z listy</em>
                          </MenuItem>
                          {regionalOffices &&
                            regionalOffices.map((office) => (
                              <MenuItem
                                key={office.objectKey}
                                value={office.objectKey}
                              >
                                {office.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      {values.regionalOffice != "" && (
                        <>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            {districtOffices == null && (
                              <LinearProgress color="success" />
                            )}
                            <TextField
                              fullWidth
                              select
                              name="districtOffice"
                              id="districtOffice"
                              variant="filled"
                              error={
                                touched.districtOffice &&
                                Boolean(errors.districtOffice)
                              }
                              helperText={
                                touched.districtOffice && errors.districtOffice
                              }
                              value={values.districtOffice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={
                                values.affectsYou === "true"
                                  ? "Z jakiego hufca jesteś?"
                                  : "Z jakiego hufca jest osoba, która doświadczyła przekroczenia granic?"
                              }
                              disabled={districtOffices == null}
                            >
                              <MenuItem value="" disabled>
                                <em>Wybierz z listy</em>
                              </MenuItem>
                              {districtOffices &&
                                districtOffices.map((office) => (
                                  <MenuItem
                                    key={office.objectKey}
                                    value={office.objectKey}
                                  >
                                    {office.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          select
                          name="ageVictim"
                          id="ageVictim"
                          variant="filled"
                          error={touched.ageVictim && Boolean(errors.ageVictim)}
                          helperText={touched.ageVictim && errors.ageVictim}
                          value={values.ageVictim}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={
                            values.affectsYou === "true"
                              ? "W jakim wieku jesteś?"
                              : "W jakim wieku jest osoba, która doświadczyła przekroczenia granic?"
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>Wybierz z listy</em>
                          </MenuItem>
                          <MenuItem value={"dziecko (do 13 roku życia)"}>
                            dziecko (do 13 roku życia)
                          </MenuItem>
                          <MenuItem
                            value={"nastolatek (od 14 do 17 roku życia)"}
                          >
                            nastolatek (od 14 do 17 roku życia)
                          </MenuItem>
                          <MenuItem
                            value={"młody dorosły (od 18 do 21 roku życia)"}
                          >
                            młody dorosły (od 18 do 21 roku życia)
                          </MenuItem>
                          <MenuItem value={"osoba dorosła"}>
                            osoba dorosła
                          </MenuItem>
                          {values.affectsYou !== "true" && (
                            <MenuItem value={"nie wiem"}>nie wiem</MenuItem>
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          name="role"
                          id="role"
                          variant="filled"
                          error={touched.role && Boolean(errors.role)}
                          helperText={
                            touched.role && errors.role
                              ? errors.role
                              : "Jaką funkcję pełnisz w ZHP? Jeśli nie pełnisz żadnej, przejdź dalej."
                          }
                          label="Twoja funkcja"
                          value={values.role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Osoba przekraczająca granice</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="nameSurnameGuilty"
                          id="nameSurnameGuilty"
                          variant="filled"
                          error={
                            touched.nameSurnameGuilty &&
                            Boolean(errors.nameSurnameGuilty)
                          }
                          helperText={
                            touched.nameSurnameGuilty &&
                            errors.nameSurnameGuilty
                              ? errors.nameSurnameGuilty
                              : "Jak nazywa się osoba, która dopuściła się naruszenia granic? Jeśli nie chcesz na tym etapie podawać tej informacji, przejdź dalej."
                          }
                          label="Imię i nazwisko osoby przekraczającej granice"
                          value={values.nameSurnameGuilty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          select
                          name="ageGuilty"
                          id="ageGuilty"
                          variant="filled"
                          error={touched.ageGuilty && Boolean(errors.ageGuilty)}
                          helperText={touched.ageGuilty && errors.ageGuilty}
                          value={values.ageGuilty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={
                            "W jakim wieku jest osoba przekraczająca granice?"
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>Wybierz z listy</em>
                          </MenuItem>
                          <MenuItem value={"dziecko (do 13 roku życia)"}>
                            dziecko (do 13 roku życia)
                          </MenuItem>
                          <MenuItem
                            value={"nastolatek (od 14 do 17 roku życia)"}
                          >
                            nastolatek (od 14 do 17 roku życia)
                          </MenuItem>
                          <MenuItem
                            value={"młody dorosły (od 18 do 21 roku życia)"}
                          >
                            młody dorosły (od 18 do 21 roku życia)
                          </MenuItem>
                          <MenuItem value={"osoba dorosła"}>
                            osoba dorosła
                          </MenuItem>
                          <MenuItem value={"nie wiem"}>nie wiem</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="infoGuilty"
                          id="infoGuilty"
                          variant="filled"
                          error={
                            touched.infoGuilty && Boolean(errors.infoGuilty)
                          }
                          helperText={
                            touched.infoGuilty && errors.infoGuilty
                              ? errors.infoGuilty
                              : "Podaj: wiek, funkcję, przynależność do środowiska (np. hufiec, chorągiew). Jeśli nie znasz jakichś danych osoby, która przekroczyła granice, napisz tak dużo, jak wiesz."
                          }
                          label="Kim jest przekraczający?"
                          value={values.infoGuilty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Opis sytuacji</h2>
                        <Typography>
                          W tej sekcji poprosimy Cię o opisanie przebiegu
                          sytuacji, do której doszło. Podaj te informacje, które
                          znasz. Jeśli nie potrafisz odpowiedzieć na jakieś
                          pytanie, omiń je.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="description"
                          id="description"
                          variant="filled"
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={touched.description && errors.description}
                          label="Opis sytuacji przekroczenia granic"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isCrime">
                            Czy sytuacja nosi znamiona przestępstwa?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isCrime"
                            name="isCrime"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isCrime}
                            error={errors.isCrime}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isCrime"
                              value="Tak"
                            />
                            <FormControlLabel
                              value="Nie"
                              control={<Radio />}
                              label="Nie"
                              name="isCrime"
                            />
                            <FormControlLabel
                              value="Nie wiem"
                              control={<Radio />}
                              label="Nie wiem"
                              name="isCrime"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {values.isCrime == "Tak" && (
                        <Grid xs={12} style={{ marginTop: 15 }}>
                          <FormControl>
                            <FormLabel id="policeInformed">
                              Czy o sytuacji poinformowano policję?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="policeInformed"
                              name="policeInformed"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.policeInformed}
                              error={errors.policeInformed}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Tak"
                                name="policeInformed"
                                value="Tak"
                              />
                              <FormControlLabel
                                value="Nie"
                                control={<Radio />}
                                label="Nie"
                                name="policeInformed"
                              />
                              <FormControlLabel
                                value="Nie wiem"
                                control={<Radio />}
                                label="Nie wiem"
                                name="policeInformed"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="actions"
                          id="actions"
                          variant="filled"
                          error={touched.actions && Boolean(errors.actions)}
                          helperText={touched.actions && errors.actions}
                          label="Czy jakieś działania zostały już podjęte?"
                          placeholder="Czy komenda hufca lub kierownik wypoczynku zareagował - w jaki sposób? Czy zostały przeprowadzone jakieś rozmowy w tej sprawie? Czy zostały wyciągnięte konsekwencje?"
                          value={values.actions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isCamp">
                            Czy do tej sytuacji doszło na obozie?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isCamp"
                            name="isCamp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isCamp}
                            error={errors.isCamp}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isCamp"
                              value="Tak"
                            />
                            <FormControlLabel
                              value="Nie"
                              control={<Radio />}
                              label="Nie"
                              name="isCamp"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {values.isCamp == "Tak" && (
                        <>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="nameSurnameCampCommisioner"
                              id="nameSurnameCampCommisioner"
                              variant="filled"
                              error={
                                touched.nameSurnameCampCommisioner &&
                                Boolean(errors.nameSurnameCampCommisioner)
                              }
                              helperText={
                                touched.nameSurnameCampCommisioner &&
                                errors.nameSurnameCampCommisioner
                              }
                              label="Imię i nazwisko komendanta obozu (kierownika wypoczynku)"
                              value={values.nameSurnameCampCommisioner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="emailAddressCampCommisioner"
                              id="emailAddressCampCommisioner"
                              variant="filled"
                              error={
                                touched.emailAddressCampCommisioner &&
                                Boolean(errors.emailAddressCampCommisioner)
                              }
                              helperText={
                                touched.emailAddressCampCommisioner &&
                                errors.emailAddressCampCommisioner
                              }
                              label="Adres e-mail komendanta obozu (kierownika wypoczynku)"
                              value={values.emailAddressCampCommisioner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="phoneNumberCampCommisioner"
                              id="phoneNumberCampCommisioner"
                              variant="filled"
                              error={
                                touched.phoneNumberCampCommisioner &&
                                Boolean(errors.phoneNumberCampCommisioner)
                              }
                              helperText={
                                touched.phoneNumberCampCommisioner &&
                                errors.phoneNumberCampCommisioner
                              }
                              label="Numer telefonu komendanta obozu (kierownika wypoczynku)"
                              value={values.phoneNumberCampCommisioner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isDistrictCommisionerGuilty">
                            Czy chcesz, aby Komendant Hufca{" "}
                            <strong>NIE został poinformowany</strong> o tym
                            zgłoszeniu i aby było ono rozpatrywana na poziomie
                            Chorągwi?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isDistrictCommisionerGuilty"
                            name="isDistrictCommisionerGuilty"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isDistrictCommisionerGuilty}
                            error={errors.isDistrictCommisionerGuilty}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isDistrictCommisionerGuilty"
                              value="Tak"
                            />
                            <FormControlLabel
                              value="Nie"
                              control={<Radio />}
                              label="Nie"
                              name="isDistrictCommisionerGuilty"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isRegionalOfficeCommisionerGuilty">
                            Czy chcesz, aby Komendant Chorągwi{" "}
                            <strong>NIE został poinformowany</strong> o tym
                            zgłoszeniu i aby było ono rozpatrywana na poziomie
                            Głównej Kwatery?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isRegionalOfficeCommisionerGuilty"
                            name="isRegionalOfficeCommisionerGuilty"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isRegionalOfficeCommisionerGuilty}
                            error={errors.isRegionalOfficeCommisionerGuilty}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isRegionalOfficeCommisionerGuilty"
                              value="Tak"
                            />
                            <FormControlLabel
                              value="Nie"
                              control={<Radio />}
                              label="Nie"
                              name="isRegionalOfficeCommisionerGuilty"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                          onChange={(event) => setFieldValue("captcha", event)}
                        />
                        {errors.captcha && touched.captcha && (
                          <Typography
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              paddingLeft: "0.75rem",
                              paddingTop: "0.25rem",
                            }}
                          >
                            {errors.captcha}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          alignSelf="left"
                          spacing={1}
                          useFlexGap
                          sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
                        >
                          <ColorButton
                            variant="contained"
                            type="submit"
                            disabled={loading}
                          >
                            PRZEŚLIJ
                          </ColorButton>
                          <ColorButton
                            onClick={() => handleReset()}
                            variant="contained"
                          >
                            ANULUJ
                          </ColorButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </>
            )}
          </Formik>
        </Stack>
      </Container>
    </Box>
  );
}
