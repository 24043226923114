import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";
import ZHP from "../assets/images/ZHP_ID.svg";
import { useNavigate } from "react-router-dom";
import ZHPGreenButtonMS from "./Buttons/ZHPGreenButtonMS";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
  padding: "10px",
  marginLeft: "20px",
};

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const isAuthenticated = useIsAuthenticated();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        variant="dense"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <img
                src={ZHP}
                style={logoStyle}
                alt="Związek Harcerstwa Polskiego"
                onClick={() => navigate("/")}
              />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  onClick={() => navigate("/")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Strona główna
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/formularz");
                  }}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Formularz
                  </Typography>
                </MenuItem>

                {isAuthenticated && (
                  <MenuItem
                    onClick={() => {
                      navigate("/zgloszenia");
                    }}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="text.primary">
                      Zgłoszenia
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    navigate("/niezbednik");
                  }}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Niezbędnik SFH
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/pomoc");
                  }}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Pomoc techniczna
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {isAuthenticated && (
                <Typography
                  variant="body2"
                  color="text.primary"
                  textAlign={"center"}
                >
                  {activeAccount.name} ({activeAccount.username})
                </Typography>
              )}

              <UnauthenticatedTemplate>
                <ZHPGreenButtonMS>ZALOGUJ SIĘ</ZHPGreenButtonMS>
              </UnauthenticatedTemplate>
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <MenuItem onClick={() => navigate("/")}>
                    Strona główna
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/formularz")}>
                    Formularz
                  </MenuItem>
                  {isAuthenticated && (
                    <MenuItem
                      onClick={() => {
                        navigate("/zgloszenia");
                      }}
                    >
                      Zgłoszenia
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => navigate("/niezbednik")}>
                    Niezbędnik SFH
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/pomoc")}>
                    Pomoc techniczna
                  </MenuItem>
                  <Divider />
                  <UnauthenticatedTemplate>
                    <MenuItem>
                      <ZHPGreenButtonMS>ZALOGUJ SIĘ</ZHPGreenButtonMS>
                    </MenuItem>
                  </UnauthenticatedTemplate>

                  {isAuthenticated && (
                    <Typography
                      variant="body2"
                      color="text.primary"
                      textAlign={"center"}
                    >
                      {activeAccount.name}
                      <br />({activeAccount.username})
                    </Typography>
                  )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
