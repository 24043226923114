import React, { useEffect, useState } from "react";
import Form from "../components/Form";
import RequestType from "../components/RequestType";
import AffectsYou from "../components/AffectsYou";

export default function FormPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Steps
  // 0 - request type
  // 1 - affects
  // 2 - form
  // 3 - confirm
  const [step, setStep] = useState(0);

  // Request types
  // 0 - anonymous
  // 1 - unanonymous
  // 2 - ZHP account
  const [requestType, setRequestType] = useState(null);

  // Affects you
  // 0 - yes
  // 1 - no
  const [affectsYou, setAffectsYou] = useState(null);

  return (
    <>
      {step === 0 && (
        <RequestType setStep={setStep} setRequestType={setRequestType} />
      )}
      {step === 1 && (
        <AffectsYou setStep={setStep} setAffectsYou={setAffectsYou} />
      )}
      {step === 2 && <Form requestType={requestType} affectsYou={affectsYou} />}
    </>
  );
}
