import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";
import { useIsAuthenticated } from "@azure/msal-react";

const items = [
  {
    login: false,
    icon: <ViewQuiltRoundedIcon />,
    title: "Zgłoszenie anonimowe",
    description:
      "Nie będziemy Cię prosić o podanie danych kontaktowych. Po przesłaniu formularza zobaczysz infromację potwierdzającą przyjęcie zgłoszenia i wygenerowany numer. Nie będziemy się w stanie z Tobą skontaktować, aby dzięki informacjom od Ciebie mieć szansę skuteczniej zaplanować działania interwencyjne w tej sprawie. Interwencja może być mniej skuteczna.",
  },
  {
    login: false,
    icon: <EdgesensorHighRoundedIcon />,
    title: "Zgłoszenie nieanonimowe",
    description:
      "Poprosimy Cię o podanie danych kontaktowych. Po przesłaniu formularza otrzymasz potwierdzenie mailowe o przyjeciu zgłoszenia i wygenerowany numer. Jeżeli będziemy mieli dodatkowe pytania lub potrzebowali szczegółów, to skontaktujemy się z Tobą.",
  },
  {
    login: true,
    icon: <DevicesRoundedIcon />,
    title: "Zgłoszenie przez konto ZHP",
    description:
      "Jeżeli należysz do ZHP i chcesz dodać zgłoszenie nieanonimowe, to skorzystaj z tej opcji. Wypełnimy formularz Twoimi danymi kontaktowymi na podstawie konta ZHP. Jeżeli będziemy mieli dodatkowe pytania lub potrzebowali szczegółów, to skontaktujemy się z Tobą.",
  },
];

export default function RequestType({ setStep, setRequestType }) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const isAuthenticated = useIsAuthenticated();

  const handleItemClick = (index) => {
    setRequestType(index);
    setStep(1);
  };

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              Rodzaj zgłoszenia
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Wybierz w jaki sposób chcesz dokonać zgłoszenia przekroczenia
              granic.
            </Typography>
          </div>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: "flex" }}
          >
            {items.map(({ login, icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                disabled={login && !isAuthenticated}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedItemIndex === index ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemIndex === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemIndex === index
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: "none" }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    {login && !isAuthenticated ? (
                      <>
                        Zaloguj sie do swojego konta MS365 (w prawym górnym
                        rogu), aby skorzystać z tej opcji.
                      </>
                    ) : (
                      <Link
                        color="primary"
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          "& > svg": { transition: "0.2s" },
                          "&:hover > svg": { transform: "translateX(2px)" },
                        }}
                        onClick={() => {
                          handleItemClick(index);
                        }}
                      >
                        <span>Rozpocznij wypełnianie</span>
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ mt: "1px", ml: "2px" }}
                        />
                      </Link>
                    )}
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
