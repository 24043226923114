import React, { useEffect, useState } from "react";
import Form from "../components/Form";
import RequestType from "../components/RequestType";
import AffectsYou from "../components/AffectsYou";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import ZHPGreenButton from "../components/Buttons/ZHPGreenButton";
import FindInPageSharpIcon from "@mui/icons-material/FindInPageSharp";
import { getIssues } from "../api/getIssues";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { enqueueSnackbar } from "notistack";

const columns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => <Chip label={params.value} color="primary" />,
  },
  { field: "regionalOffice", headerName: "Chorągiew", width: 130 },
  { field: "districtOffice", headerName: "Hufiec", width: 130 },
  { field: "summary", headerName: "Nazwa zgłoszenia", width: 280 },
  { field: "created", headerName: "Utworzono", width: 130 },
  { field: "modified", headerName: "Zmodyfikowano", width: 130 },
  {
    field: "jira",
    headerName: "JIRA",
    sortable: false,
    width: 100,
    renderCell: (value, row) => (
      <Link
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            "https://jira.zhp.pl/plugins/servlet/desk/portal/17/" + value.id,
            "_blank"
          )
        }
      >
        Otwórz
      </Link>
    ),
  },
];

const paginationModel = { page: 0, pageSize: 100 };

export default function IssuesPage() {
  const { instance } = useMsal();

  const getToken = async () => {
    const account = instance.getActiveAccount();

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    return response.accessToken;
  };

  const [issues, setIssues] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getToken().then((token) => {
      getIssues(token)
        .then((res) => {
          setIssues(res.data);
        })
        .catch((error) => {
          enqueueSnackbar("[Zgłoszenia] Błąd pobierania danych: " + error, {
            variant: "error",
          });
        });
    });
  }, []);

  return (
    <>
      <Container
        id="features"
        sx={{ py: { xs: 8, sm: 16 }, pt: { xs: 14, sm: 20 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <Typography component="h2" variant="h4" color="text.primary">
                Zgłoszenia
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: { xs: 2, sm: 4 } }}
              >
                Przejrzyj zgłoszenia, w których uczestniczysz.{" "}
                <strong>
                  Nie zobaczysz tutaj zgłoszeń, które zostały przez Ciebie
                  dodane.
                </strong>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={issues}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                sx={{ border: 0 }}
                loading={!issues}
                localeText={{
                  noRowsLabel: "Brak zgłoszeń spełniających warunki!",
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
