import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Layout from "./Layout";

// Components
import Hero from "./components/Hero";
import FormPage from "./pages/FormPage";
import IssuesPage from "./pages/IssuesPage";

// MSAL
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import HelpPage from "./pages/HelpPage";
import EssentialsPage from "./pages/EssentialsPage";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Hero />} />
              <Route path="formularz" element={<FormPage />} />
              <Route path="zgloszenia" element={<IssuesPage />} />
              <Route path="pomoc" element={<HelpPage />} />
              <Route path="niezbednik" element={<EssentialsPage />} />
            </Route>
          </Routes>
        </MsalProvider>
      </BrowserRouter>
    </React.StrictMode>
  );

  reportWebVitals();
});
