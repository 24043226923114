import React from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

export default function ZHPGreenButton({ children }) {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  const { instance } = useMsal();

  const handleSignIn = async () => {
    await instance.handleRedirectPromise();

    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      // No user signed in
      instance.loginRedirect().then((response) => {
        // After a successful login set the active account to be the user that just logged in
        instance.setActiveAccount(response.account);
      });
    }
  };

  return (
    <ColorButton onClick={handleSignIn} fullWidth>
      <Typography fontWeight={600}>{children}</Typography>
    </ColorButton>
  );
}
