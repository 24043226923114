import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { addHelpIssue } from "../api/addHelpIssue";
import { enqueueSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

export default function HelpPage() {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  const [loading, setLoading] = useState(false);
  const [issueKey, setIssueKey] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const recaptchaRef = React.createRef();

  const schema = Yup.object().shape({
    captcha: Yup.string().required(
      "Weryfikacja reCAPTCHA jest wymagana!                                                                                                                                                                                                                                                                                                                                                                                                                                                                      "
    ),
    description: Yup.string()
      .min(10, "Opis problemu jest zdecydowanie za krótki!")
      .required("Opis problemu jest wymagany!"),
    emailAddress: Yup.string()
      .email("Adres e-mail jest niepoprawny!")
      .required("Adres e-mail jest wymagany!"),
  });

  return (
    <>
      <Container
        id="features"
        sx={{ py: { xs: 8, sm: 16 }, pt: { xs: 14, sm: 20 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <Typography component="h2" variant="h4" color="text.primary">
                Pomoc techniczna CSZ SFH
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: { xs: 2, sm: 2 } }}
              >
                Masz problem techniczny? Nie możesz wsyłać zgłoszenia
                przekroczenia granic? Nie widzisz zgłoszeń, w których
                uczestniczysz? Wypełnij poniższy formularz.
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight={"bold"}
              >
                Formularz dotyczy wyłącznie problemów związanych z Centralnym
                Systemem Zgłoszeń Safe From Harm.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              textAlign="left"
              color="text.secondary"
              sx={{ alignSelf: "center" }}
            >
              <Formik
                validationSchema={schema}
                initialValues={{
                  emailAddress: "",
                  description: "",
                  captcha: null,
                }}
                onSubmit={(values) => {
                  setLoading(true);

                  addHelpIssue(JSON.stringify(values, null, 2))
                    .then((res) => {
                      setLoading(false);
                      setIssueKey(res.data.key);
                    })
                    .catch((error) => {
                      setLoading(false);
                      enqueueSnackbar(
                        "[Pomoc techniczna] Błąd wysyłania zgłoszenia: " +
                          error,
                        {
                          variant: "error",
                        }
                      );
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  handleReset,
                  setFieldValue,
                }) => (
                  <>
                    {loading && (
                      <Grid style={{ marginTop: 20 }}>
                        Trwa ładowanie danych...
                        <LinearProgress color="success" />
                      </Grid>
                    )}
                    {issueKey && (
                      <Grid style={{ marginTop: 20 }}>
                        <Alert
                          icon={<CheckIcon fontSize="inherit" />}
                          severity="success"
                          sx={{ mb: 3, fontSize: "large" }}
                        >
                          Twoje zgłoszenie pomocy technicznej zostało dodane!
                          <br />
                          Numer zgłoszenia: <strong>{issueKey}</strong>
                        </Alert>

                        <ColorButton
                          onClick={() => {
                            handleReset();
                            setIssueKey(null);
                          }}
                          variant="contained"
                        >
                          POWRÓT
                        </ColorButton>
                      </Grid>
                    )}
                    {!loading && !issueKey && (
                      <form onSubmit={handleSubmit}>
                        <Grid container>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="emailAddress"
                              id="emailAddress"
                              variant="filled"
                              error={
                                touched.emailAddress &&
                                Boolean(errors.emailAddress)
                              }
                              helperText={
                                touched.emailAddress && errors.emailAddress
                                  ? errors.emailAddress
                                  : "Twój adres e-mail zostanie wykorzystany do kontaktu z Tobą w celu rozwiązania problemu."
                              }
                              label={"Twój adres e-mail"}
                              value={values.emailAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="email"
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              name="description"
                              id="description"
                              variant="filled"
                              error={
                                touched.description &&
                                Boolean(errors.description)
                              }
                              helperText={
                                touched.description && errors.description
                              }
                              label="Opis problemu"
                              placeholder="Opisz szczegółowo co się dzieje. Wskaż w jaki sposób możemy odtworzyć problem podczas jego weryfikacji. Podaj kroki, które należy wykonać by wystąpił problem."
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={
                                process.env.REACT_APP_RECAPTCHA_CLIENT_KEY
                              }
                              onChange={(event) =>
                                setFieldValue("captcha", event)
                              }
                            />
                            {errors.captcha && touched.captcha && (
                              <Typography
                                style={{
                                  color: "#d32f2f",
                                  fontSize: "0.75rem",
                                  paddingLeft: "0.75rem",
                                  paddingTop: "0.25rem",
                                }}
                              >
                                {errors.captcha}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              alignSelf="left"
                              spacing={1}
                              useFlexGap
                              sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
                            >
                              <ColorButton
                                variant="contained"
                                type="submit"
                                disabled={loading}
                              >
                                PRZEŚLIJ
                              </ColorButton>
                              <ColorButton
                                onClick={() => {
                                  handleReset();
                                  recaptchaRef.current.reset();
                                }}
                                variant="contained"
                              >
                                ANULUJ
                              </ColorButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </>
                )}
              </Formik>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
