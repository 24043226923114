import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { addHelpIssue } from "../api/addHelpIssue";
import { enqueueSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

export default function EssentialsPage() {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Container
        id="features"
        sx={{ py: { xs: 8, sm: 16 }, pt: { xs: 14, sm: 20 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <Typography component="h2" variant="h4" color="text.primary">
                Niezbędnik SFH
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: { xs: 2, sm: 2 } }}
              >
                Znajdziesz tutaj najbardziej przydatne linki i materiały
                dotyczące SFH.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ColorButton
              variant="contained"
              onClick={() =>
                window.open("https://edu.zhp.pl/course/view.php?id=47")
              }
            >
              Szkolenie z Safe From Harm
            </ColorButton>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <ColorButton
              variant="contained"
              onClick={() => window.open("https://konta-sfh.zhp.pl/")}
            >
              Zakładanie kont SZKOLENIOWYCH dla osób bez maila ZHP
            </ColorButton>
          </Grid>
          <Grid item xs={12}>
            <ColorButton
              variant="contained"
              onClick={() =>
                window.open(
                  "https://gkzhp.sharepoint.com/SitePages/Safe-From-Harm.aspx"
                )
              }
            >
              SharePoint Safe From Harm
            </ColorButton>
          </Grid>
          <Grid item xs={12}>
            <ColorButton
              variant="contained"
              onClick={() =>
                window.open("https://cbp.zhp.pl/m?search=safe%20from%20harm")
              }
            >
              POLITYKA SFH I MATERIAŁY EDUKACYJNE
            </ColorButton>
          </Grid>
          <Grid item xs={12}>
            <ColorButton
              variant="contained"
              onClick={() =>
                window.open("https://forms.office.com/e/0RejFS2yZt")
              }
            >
              FORMULARZ EWALUACYJNY POLITYKI SAFE FROM HARM
            </ColorButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
